.App {
    padding: 8px;
}

.App-logo {
    height: 30;
    margin-bottom: 8px;
}

.App-chatContainer {
    margin-top: 'auto';
    padding: 8px;
}

.App-chatContainer > *:first-child > *:first-child {
    border-radius: 12px;
}

.App-chatContainer button:hover {
    cursor: pointer;
}

.App-chatContainer .ac-container, .ac-pushButton {
    border-radius: 12px;
}

.App-chatContainer .ac-pushButton {
    border-radius: 24px;
}